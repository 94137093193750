<script>
import CalendarMonth from "../../../components/CalendarsMonth.vue";
import BackArrow from "../../../components/BackArrow/index.vue";
import Helpers from "@/mixins";
import { apiUrlByEnviroment } from "../../../utils";
export default {
  components: {
    CalendarMonth,
    BackArrow,
  },
  mixins: [Helpers],
  data: () => ({
    menu: null,
    date: null,
    showExcessBaggageModal: false,
    cancelDialog: false,
    childDialog: false,
    dialog: false,
    items: [],
    search: {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      state: "",
    },
    tiposDocumento: [
      { id: "1", descricao: "RG" },
      { id: "2", descricao: "TÍTULO DE ELEITOR" },
      { id: "3", descricao: "PASSAPORTE" },
      { id: "4", descricao: "CNH" },
      { id: "5", descricao: "OUTROS" },
    ],
    clientes: [],
    message: {
      snacshow: false,
      text: "",
      type: "",
      mode: "",
      timeout: 5000,
    },
    dadosPassagem: [],
    valid: true,
    fieldRules: [(v) => !!v || "Este campo é requerido"],
    itemSelected: {},
  }),
  created() {
    this.Helpers;
    this.initialize();
  },

  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.downloadFile();
      } else {
        this.message.snacshow = true;
        this.message.type = "warning";
        this.message.text =
          "Atencão um ou mais campos precisam ser preenchidos!";
      }
    },
    initialize() {
      this.$store.dispatch("activeLoader", true);
      this.search.id_operador = this.$store.getters.getOperador;
      this.$http.post(`/pdv/vendas`, this.search).then((r) => {
        this.items = r.data;
        this.$store.dispatch("activeLoader", false);
      });
    },
    clearForm() {
      this.search = {
        date: null,
        state: "",
      }
    },
    directDownload(file) {
      const link = document.createElement('a');
      link.href = `${apiUrlByEnviroment[process.env.NODE_ENV.trim()]}/downloads/${file}`;
      link.target = '_blank'; // adic
      link.download = file;
      link.click();
    },
    downloadFile() {
      this.$store.dispatch("activeLoader", true);
      const date = new Date(this.search.date);
      const { year, month } = { year: date.getUTCFullYear(), month: date.getUTCMonth() + 1 };
      const sendSearch = {
        year: year,
        month: month < 10 ? "0"+ month : month,
        state: this.search.state
      }
      this.$http
        .post("/bpe/resumezip", sendSearch)
        .then((result) => {
          this.$store.dispatch("activeLoader", false);
          const { fileName, success } = result.data;
          if (success) {
            this.directDownload(fileName);
            this.dialog = false;
          } else {
            this.message.text = result.data.warning;
            this.message.snacshow = true;
            this.message.type = "error";
          }
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "error";
        });
    },

  },
};
</script>
